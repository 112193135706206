<template>
    <div class="p-home" v-loading="!isReady">
        <div class="hd">
            <h1>MML Worker</h1>
        </div>
        <div class="bd">
            <el-row class="form">
                <el-col
                    :xs="{ offset: 1, span: 22 }"
                    :sm="{ offset: 6, span: 12 }"
                    :lg="{ offset: 8, span: 8 }"
                >
                    <el-form
                        :model="loginForm"
                        :rules="loginFormRules"
                        ref="loginForm"
                        class="login-form"
                        label-width="54px"
                        v-loading="isLoading"
                    >
                        <el-form-item prop="email" label="邮箱">
                            <el-input
                                placeholder="邮箱"
                                v-model="loginForm.email"
                                class="handle-enter"
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="password" label="密码">
                            <el-input
                                v-model="loginForm.password"
                                type="password"
                                placeholder="密码"
                                class="handle-enter"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div class="btn-wrap">
                                <div class="btn-parent">
                                    <el-button
                                        type="primary"
                                        @click="onLogin"
                                        class="J_btnLogin"
                                        >登录</el-button
                                    >
                                </div>
                                <div class="link-parent">
                                    <el-link
                                        type="info"
                                        @click="onForgetPasswrod"
                                        >忘记密码？</el-link
                                    >
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-row class="tip">
                <p style="text-align: center">
                    请使用 Chrome 、 Firefox 等标准浏览器。
                </p>
            </el-row>
        </div>
        <div class="ft"></div>
    </div>
</template>

<script>
// // @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
let API = require('../js/api.js')

export default {
    name: "Home",
    components: {},
    data() {
        return {
            isReady: false,
            isLoading: false,
            loginForm: {
                email: "",
                password: "",
            },
            loginFormRules: {
                email: [
                    {
                        required: true,
                        message: "请填写登录邮箱",
                        trigger: "blur",
                    },
                    {
                        min: 13,
                        max: 64,
                        message: "请输入正确的邮箱地址",
                        trigger: "blur",
                    },
                    {
                        validator: function (rule, value, callback) {
                            if (!/.+@mmldigi.com$/.test(value)) {
                                callback(
                                    new Error("仅支持 @mmldigi.com 的邮箱")
                                );
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请填写登录密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 32,
                        message: "密码长度: 6 ~ 32",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        let that = this;
        let auth = false;
        that.$ajax("/account/get-auth-info", {})
            .done(function (ret) {
                if (ret.user) {
                    auth = true;
                }
            })
            .fail(function () {
                //
            })
            .always(function () {
                if (auth) {
                    if (
                        that.$router.currentRoute.query &&
                        that.$router.currentRoute.query.redirect
                    ) {
                        // http://192.168.0.8:8080/#/?redirect=%2Fwork%2Fsystem%2Faccounts
                        that.$router.push({
                            path: that.$router.currentRoute.query.redirect,
                        });
                    } else {
                        that.$router.push({ name: "work-center" });
                    }
                } else {
                    that.init();
                    that.isReady = true;
                }
            });
    },
    methods: {
        init() {
            let that = this;
            let elements = document.getElementsByClassName("handle-enter");
            for (let i = 0; i < elements.length; i++) {
                let element = elements[i];
                element.addEventListener("keyup", function (e) {
                    if (e.keyCode === 13) {
                        that.onLogin();
                    }
                });
            }
        },
        onLogin() {
            let that = this;
            that.$refs["loginForm"].validate((valid) => {
                if (!valid) {
                    return;
                }
                that.isLoading = true;
                // that.$ajax("/account/login", that.loginForm)
                //     .done(function (data) {
                //         console.log("done");
                //         console.log(data);
                //         if (
                //             that.$router.currentRoute.query &&
                //             that.$router.currentRoute.query.redirect &&
                //             that.$router.currentRoute.query.redirect !== "/"
                //         ) {
                //             // http://192.168.0.8:8080/#/?redirect=%2Fwork%2Fsystem%2Faccounts
                //             that.$router.push({
                //                 path: that.$router.currentRoute.query.redirect,
                //             });
                //         } else {
                //             that.$router.push({ name: "work-center" });
                //         }
                //     })
                //     .fail(function () {
                //         console.log("fail");
                //         that.isLoading = false;
                //     })
                //     .always(function () {
                //         console.log("always");
                //     });
                API.account.login(that.loginForm).then(data => {
                    console.log(data)
                    if (
                        that.$router.currentRoute.query &&
                        that.$router.currentRoute.query.redirect &&
                        that.$router.currentRoute.query.redirect !== "/"
                    ) {
                        // http://192.168.0.8:8080/#/?redirect=%2Fwork%2Fsystem%2Faccounts
                        that.$router.push({
                            path: that.$router.currentRoute.query.redirect,
                        });
                    } else {
                        that.$router.push({ name: "work-center" });
                    }
                }).catch(() => {
                }).then(() => {
                    that.isLoading = false;
                })
            });
        },
        onForgetPasswrod() {
            this.$router.push({ name: "reset" });
        },
    },
};
</script>

<style lang="less">
.p-home {
    .hd {
        h1 {
            font-size: 36px;
            text-align: center;
            padding: 20px 0 40px 0;
        }
    }
    .bd {
        .btn-wrap {
            display: flex;
        }

        .btn-parent {
            flex: 1;
        }

        .tip {
            padding: 20px;
            color: #abc;
        }
    }
}
</style>
