import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import ElementUI from "element-ui";
import App from "./App.vue";
import routerConfig from "./router";
import storeConfig from "./store";
import tools from "./js/tools";

import "./less/main.less";
import "element-ui/lib/theme-chalk/index.css";

let vueInstance;

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(ElementUI);

// Vue.prototype.$axios = axios
Vue.prototype.$ajax = tools.ajax;

const router = new VueRouter({
    routes: routerConfig,
});
router.beforeEach((to, from, next) => {
    let evt = document.createEvent("HTMLEvents");
    evt.initEvent("vue-router-changing", false, false);
    document.dispatchEvent(evt);
    next();
});
router.afterEach(() => {
    // to, from
    let evt = document.createEvent("HTMLEvents");
    evt.initEvent("vue-router-changed", false, false);
    document.dispatchEvent(evt);
});

const store = new Vuex.Store(storeConfig);

tools.ajax.autoErrorHandler = function (err) {
    if (err.status === 419) {
        window.location.reload();
        return;
    }
    if (err.message === "Login Required") {
        vueInstance.$router.push({
            name: "Home",
            query: { redirect: vueInstance.$router.currentRoute.path },
        });
    } else {
        vueInstance.$alert(err.message);
    }
};

vueInstance = new Vue({
    router,
    store,
    render: (h) => h(App),
});

let token = tools.getClientToken();
if (token) {
    vueInstance.$mount("#app");
} else {
    tools
        .ajax("/get_csrf_token", {}, { method: "GET", autoHandleError: false })
        .done(function () {
            vueInstance.$mount("#app");
        })
        .fail(function (e) {
            window.alert(
                "页面初始化失败。请刷新页面。如果持续失败，请联系技术部。"
            );
            console.log(e);
        });
}
