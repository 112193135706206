const moment = require("moment")

let saveClientToken = function (token) {
	window._mml_worker_client_token = token
}
let getClientToken = function () {
	return window._mml_worker_client_token || ''
}

let ajax = function (url, data = {}, config = {}) {
	let doneCallback, failCallback, alwaysCallback
	let xhr = new XMLHttpRequest()

	// 自动处理错误
	if (typeof config.autoHandleError === 'undefined') {
		config.autoHandleError = true
		if (typeof ajax.autoErrorHandler !== 'function') {
			ajax.autoErrorHandler = function (err) {
				console.log(err)
			}
		}
	}
	if (typeof config.method === 'undefined') {
		config.method = 'POST'
	}

	xhr.open(config.method, url)

	// 处理 header 和 data
	if (typeof data === 'object') {
		xhr.setRequestHeader('Content-Type', 'application/json')
		data = JSON.stringify(data)
	} else if (typeof data === 'string') {
		let contentType = config.headers
			? (config.headers['content-type'] || config.headers['Content-type'] || config.headers['Content-Type'])
			: 'application/x-www-form-urlencoded'
		xhr.setRequestHeader('Content-Type', contentType)
	} else {
		xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
	}
	xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
	let token = getClientToken()
	if (token) {
		xhr.setRequestHeader('X-CSRF-TOKEN', token)
	}

	// 处理响应情况
	xhr.onreadystatechange = function () {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
				let contentType = xhr.getResponseHeader('content-type')
				let ret
				if (contentType && contentType.indexOf('application/json') > -1) {
					ret = JSON.parse(xhr.responseText)
					if (ret.success === true) {
						if (ret.data.client_token) {
							saveClientToken(ret.data.client_token)
						}
						doneCallback && doneCallback(ret.data)
					} else if (ret.success === false) {
						let err = new Error(ret.errmsg)
						if (config.autoHandleError === true) {
							ajax.autoErrorHandler && ajax.autoErrorHandler(err)
						}
						failCallback && failCallback(err)
					} else {
						// 如果 ret.success 不是 true 也不是 false ，这应该是特殊情况，有可能后端崩了等。也有可能返回的是 html 之类的。
						doneCallback && doneCallback(ret)
					}
				} else {
					// 如果不是 json ，直接返回响应内容。
					ret = xhr.responseText
					doneCallback && doneCallback(ret)
				}
				// console.log(xhr.getAllResponseHeaders())
				// console.log(xhr.getResponseHeader('content-type'))
			} else {
				let errmsg = '请求失败，请稍后重试。' + xhr.status
				let err = new Error(errmsg);
				err.status = xhr.status
				if (config.autoHandleError === true) {
					ajax.autoErrorHandler && ajax.autoErrorHandler(err)
				}
				failCallback && failCallback(err)
			}
			alwaysCallback && alwaysCallback()
		}
	}

	// 发送数据
	xhr.send(data)

	// 回调处理
	let handler = {}

	handler.done = function (callback) { doneCallback = callback; return handler; }
	handler.fail = function (callback) { failCallback = callback; return handler; }
	handler.always = function (callback) { alwaysCallback = callback; return handler; }

	return handler
}

let delayRun = (time, callback) => {
	return new Promise(function (resolve, reject) {
		setTimeout(() => {
			if (callback && typeof callback === 'function') {
				resolve(callback())
			} else {
				reject(new Error('[delayRun] Illegal callback funciton'))
			}
		}, time);
	})
}

let delay = time => {
	return new Promise(function (resolve) {
		setTimeout(() => {
			resolve()
		}, time);
	})
}

let getRandomString = (len, str = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz') => {
	let result = ''
	for (let i = 0; i < len; i++) {
		let index = Math.floor(Math.random() * str.length)
		result += str[index]
	}
	return result
}

let getDataVersion = function (name) {
	return new Promise((resolve) => {
		ajax('/get_data_version')
		.done(arr => {
			resolve(arr)
		})
		.fail(() => {
			resolve([{ name, version: '' }])
		})
	}).then(arr => {
		if (name) {
			let found = arr.find(item => item.name === name)
			if (found) {
				return [ found ]
			} else {
				return [ { name, version: '' } ]
			}
		} else {
			return arr
		}
	})
}

let getVersion = function (name) {
	let item = window.localStorage.getItem('data_version')
	if (item) {
		let obj = JSON.parse(item)
		if (obj[name]) {
			return obj[name]
		} else {
			return ''
		}
	} else {
		return ''
	}
}

let setVersion = function (name, version) {
	let item = window.localStorage.getItem('data_version')
	let obj
	if (item) {
		obj = JSON.parse(item)
	} else {
		obj = {}
	}
	obj[name] = version
	window.localStorage.setItem('data_version', JSON.stringify(obj))
}

let tsToDt = ts => moment(ts * 1000).format('YYYY-MM-DD HH:mm:ss')

module.exports = {
	getClientToken,
	ajax,
	delayRun,
	delay,
	getRandomString,
	getDataVersion, getVersion, setVersion,
	tsToDt,
}
