export default {
    state: {
        user: {},
        normalLinkList: {},
    },
    mutations: {
        updateUser(state, user) {
            state.user = user;
        },
        updateNormalLinkList(state, list) {
            state.normalLinkList = list;
        },
    },
    actions: {},
    modules: {},
};
