let { ajax } = require("./tools");

let request = (url, param) => {
    return new Promise((resolve, reject) => {
        ajax(url, param)
            .done(function (d) {
                resolve(d);
            })
            .fail(function (e) {
                reject(e);
            });
    });
};

let API = {
    account: {
        login(param) {
            return request("/account/login", param);
        },
        menu() {
            return request("/account/get-menu");
        },
    },
    flow: {
        get(param) {
            return request("/flow/get", param);
        },
        start(type, id) {
            return request("/flow/start", { type, id });
        },
        end(type, id) {
            return request("/flow/end", { type, id });
        },
        to(param) {
            return request("/flow/to", param);
        },
    },
};

module.exports = API;
