<template>
	<div v-loading="changingRoute">
		<router-view />
	</div>
</template>

<style>
</style>

<script>
export default {
	data () {
		return {
			changingRoute: false
		}
	},
	computed: {
	},
	mounted () {
		let that = this
		document.addEventListener('vue-router-changing', function () {
			that.changingRoute = true
		})
		document.addEventListener('vue-router-changed', function () {
			that.changingRoute = false
		})
	}
}
</script>
