<template>
    <div class="p-404">404 Not Found</div>
</template>

<style lang="less"></style>

<script>
export default {
    data() {
        return {};
    },
};
</script>
