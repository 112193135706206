import Home from "../views/Home.vue";
import Page404 from "../views/404.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: "/reset",
        name: "reset",
        component: () =>
            import(/* webpackChunkName: "reset" */ "../views/reset.vue"),
    },
    {
        path: "/work",
        name: "work",
        component: () =>
            import(/* webpackChunkName: "work" */ "../views/work.vue"),
        children: [
            {
                path: "center",
                name: "work-center",
                component: () =>
                    import(
                        /* webpackChunkName: "work-center" */ "../views/work-center.vue"
                    ),
            },
            {
                path: "contract/customer",
                name: "contract-customer",
                component: () =>
                    import(
                        /* webpackChunkName: "contract-customer" */ "../views/contract-customer.vue"
                    ),
            },
            {
                path: "contract/management",
                name: "contract-management",
                component: () =>
                    import(
                        /* webpackChunkName: "contract-management" */ "../views/contract-management.vue"
                    ),
            },
            {
                path: "contract/detail",
                name: "contract-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "contract-detail" */ "../views/contract-detail.vue"
                    ),
            },
            {
                path: "project/list",
                name: "project-list",
                component: () =>
                    import(
                        /* webpackChunkName: "project-list" */ "../views/project-list.vue"
                    ),
            },
            {
                path: "project/detail",
                name: "project-detail",
                component: () =>
                    import(
                        /* webpackChunkName: "project-detail" */ "../views/project-detail.vue"
                    ),
            },
            {
                path: "servers",
                name: "servers",
                component: () =>
                    import(
                        /* webpackChunkName: "servers" */ "../views/servers.vue"
                    ),
                children: [
                    {
                        path: "list",
                        name: "server-list",
                        component: () =>
                            import(
                                /* webpackChunkName: "server-list" */ "../views/server-list.vue"
                            ),
                    },
                    {
                        path: "add",
                        name: "server-add",
                        component: () =>
                            import(
                                /* webpackChunkName: "server-add" */ "../views/server-add.vue"
                            ),
                    },
                ],
            },
            {
                path: "personal/password",
                name: "personal-password",
                component: () =>
                    import(
                        /* webpackChunkName: "personal-password" */ "../views/personal-password.vue"
                    ),
            },
            {
                path: "change-log",
                name: "change-log",
                component: () =>
                    import(
                        /* webpackChunkName: "change-log" */ "../views/change-log.vue"
                    ),
            },
            {
                path: "system/accounts",
                name: "system-accounts",
                component: () =>
                    import(
                        /* webpackChunkName: "system-accounts" */ "../views/system-accounts.vue"
                    ),
            },
            {
                path: "link",
                name: "work-link",
                component: () => import("../views/work-link/index.vue"),
            },
        ],
    },
    {
        path: "*",
        name: "404",
        component: Page404,
    },
];

export default routes;
